import React from 'react';
import * as Styled from './imageWithTextLinksSVGStyles';
import { BLOCKS, INLINES, MARKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import useContentfulAssets from '../../hooks/useContentfulAssets';
import isExternalUrl, {
  addTrailingSlash,
  isExternalUrlHref,
} from '../../utils';
import { HorizontalBreak } from '../../styles';

const ImageWithTextLinksSVG = ({ sectionData }) => {
  const optionsMainStyle = {
    renderMark: {
      [MARKS.BOLD]: (i) => <Styled.ParaBold>{i}</Styled.ParaBold>,
    },
    renderNode: {
      [BLOCKS.HEADING_2]: (node, children) => (
        <Styled.HeaderH2>{children}</Styled.HeaderH2>
      ),
      [BLOCKS.HEADING_3]: (node, children) => (
        <Styled.TitleStyle>{children}</Styled.TitleStyle>
      ),
      [BLOCKS.HEADING_4]: (node, children) => (
        <Styled.HeaderH4>{children}</Styled.HeaderH4>
      ),
      [BLOCKS.HEADING_6]: (node, children) => (
        <Styled.HeaderH6>{children}</Styled.HeaderH6>
      ),
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <Styled.ParaStyle>{children}</Styled.ParaStyle>
      ),
      [INLINES.HYPERLINK]: (node, children) => (
        <Styled.LinkStyle
          href={
            isExternalUrlHref(node?.data?.uri)
              ? node?.data?.uri
              : addTrailingSlash(
                  process.env.GATSBY_DOMAIN_URL + node?.data?.uri
                )
          }
          target={isExternalUrl(node?.data?.uri) ? '_blank' : '_self'}>
          {children}
        </Styled.LinkStyle>
      ),
      [BLOCKS.UL_LIST]: (node, children) => (
        <Styled.UnorderedList>{children}</Styled.UnorderedList>
      ),
      [BLOCKS.LIST_ITEM]: (node, children) => (
        <Styled.ListItem>{children}</Styled.ListItem>
      ),
      [BLOCKS.HR]: () => <HorizontalBreak />,
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        const asset = useContentfulAssets(node?.data?.target?.sys?.id);
        return asset?.node?.file?.url?.includes('image') ? (
          <Styled.ImageStyle
            src={asset?.node?.file?.url}
            alt={asset?.node?.title}
          />
        ) : (
          <></>
        );
      },
    },
    renderText: (text) =>
      text.split('\n').flatMap((text, i) => [i > 0 && <br key={i} />, text]),
  };
  return (
    <Styled.Container>
      {sectionData?.contentDetails &&
        documentToReactComponents(
          JSON.parse(sectionData?.contentDetails?.raw),
          optionsMainStyle
        )}
    </Styled.Container>
  );
};
export default ImageWithTextLinksSVG;
